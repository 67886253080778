const Footer = () => (
  <footer className="bg-gray-100">
    <div className="w-full container mx-auto p-4 md:px-6 md:py-8">
      <div className="sm:flex sm:items-center sm:justify-between">
        <a
          href="https://flowbite.com/"
          className="flex items-center mb-4 sm:mb-0"
        >
          <img
            src={require("../assets/images/logo.png")}
            className="h-8 mr-3"
            alt="Flowbite Logo"
          />
        </a>
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          ©{" "}
          <a href="https://flowbite.com/" className="hover:underline">
            SmartCraft
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
          <li>
            <a href="/" className="mr-4 hover:underline md:mr-6">
              Нүүр хуудас
            </a>
          </li>
          <li>
            <a href="/about" className="mr-4 hover:underline md:mr-6 ">
              Бидний тухай
            </a>
          </li>
          <li>
            <a href="/service" className="mr-4 hover:underline md:mr-6 ">
              Бүтээгдэхүүн
            </a>
          </li>
          <li>
            <a href="/contact" className="hover:underline">
              Холбоо барих
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export async function getServerSideProps() {
  return {
    props: { url: process.env.REACT_APP_BASE_API_URL },
  };
}

export default Footer;
