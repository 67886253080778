import React from "react";
import "./App.css";
import AppNavbar from "./components/header";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Contact from "./pages/contact";
import About from "./pages/about";
import Service from "./pages/service";
import NotFound from "./pages/404";
import Footer from "./components/footer";

function App() {
  return (
    <>
      <div className="App h-screen">
        <AppNavbar />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/home" Component={Home} />
          <Route path="/about" Component={About} />
          <Route path="/service" Component={Service} />
          <Route path="/contact" Component={Contact} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
