import p31 from '../assets/images/p31.jpeg';
import p32 from '../assets/images/p32.jpeg';
import p33 from '../assets/images/p33.jpeg';
import p34 from '../assets/images/p34.jpeg';
import p35 from '../assets/images/p35.jpeg';
import p36 from '../assets/images/p36.jpeg';
const features = [
  { name: "Авто замын хэв хашмал", description: "Designed by Good Goods, Inc." },
  {
    name: "Төмөр замын хэв хашмал    ",
    description:
      "Solid walnut base with rare earth magnets and powder coated steel card cover",
  },
  { name: "Коллекторын хайрцаг", description: '6.25" x 3.55" x 1.15"' },
  { name: "Галын хайрцаг", description: "Hand sanded and finished with natural oil" },
  { name: "Люк таг", description: "Wood card tray and 3 refill packs" },
  {
    name: "Хогийн сав",
    description:
      "Made from natural materials. Grain and color vary with each item.",
  },
];



export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Хэв хашмал, төмөр хийцлэл
          </h2>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src={p31}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={p32}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={p33}
            alt="Side of walnut card tray with card groove and recessed card area."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={p34}
            alt="Walnut card tray filled with cards and card angled in dedicated groove."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={p35}
            alt="Side of walnut card tray with card groove and recessed card area."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={p36}
            alt="Walnut card tray filled with cards and card angled in dedicated groove."
            className="rounded-lg bg-gray-100"
          />
        </div>
      </div>
    </div>
  );
}
