import p21 from "../assets/images/p21.jpeg";
import p22 from "../assets/images/p22.jpeg";
import p23 from "../assets/images/p23.jpeg";
import p24 from "../assets/images/p24.jpeg";
const features = [
  {
    name: "Ерөнхий оролтын самбар",
    description: "Designed by Good Goods, Inc.",
  },
  {
    name: "АВР-ийн самбар",
    description:
      "Solid walnut base with rare earth magnets and powder coated steel card cover",
  },
  { name: "Гэрэлтүүлгийн самбар", description: '6.25" x 3.55" x 1.15"' },
  {
    name: "Түр тэжээлийн зөөврийн самбар",
    description: "Hand sanded and finished with natural oil",
  },
  {
    name: "Давхрын самбар",
    description: "Designed by Good Goods, Inc.",
  },
  {
    name: "Хуваарилах самбар",
    description:
      "Solid walnut base with rare earth magnets and powder coated steel card cover",
  },
  { name: "Ослын гэрэлтүүлгийн самбар", description: '6.25" x 3.55" x 1.15"' },
  {
    name: "Тоолууртай самбар",
    description: "Hand sanded and finished with natural oil",
  },
];

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Дотор цахилгаан хангамж
          </h2>
          <p className="mt-4 text-gray-500">
            ИТД-нд хэрэглэгдэж байгаа цахилгааны тоноглолууд нь чанарын
            шаардлага хангасан ба үйлдвэрлэгчийн сертификат паспортууд болон
            угсарч үйлдвэрлэх эрхтэй албан ёсны гэрчилгээнүүд дагалдана.
            Хэмжүүрийн тоноглолууд нь Стандарт хэмжилзүйн газраар баталгаажсан
            шалгалт тохируулгын гэрчилгээтэй. ЦБД БД43-101-03 болон УБЦТС
            ХХК-ийн шаардлагыг бүрэн хангасан. Ашиглалтын хугацаа 25-30 жил
            байна.
          </p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src={p21}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={p22}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={p23}
            alt="Side of walnut card tray with card groove and recessed card area."
            className="rounded-lg bg-gray-100"
          />
          <img
            src={p24}
            alt="Walnut card tray filled with cards and card angled in dedicated groove."
            className="rounded-lg bg-gray-100"
          />
        </div>
      </div>
    </div>
  );
}
