import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export default function Example() {
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/home");
  };

  return (
    <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl">
            <span className="sr-only">Алдаа </span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
Уучлаарай, Таны хайсан хуудас олдсонгүй.
          </p>
          <p className="mt-4 mb-8 dark:text-gray-400">
Та манай нүүр хуудсаар зочлоорой
          </p>
          <Button
            className="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900"
            onClick={goHome}
          >
Нүүр хуудас руу бүцах
          </Button>
        </div>
      </div>
    </section>
  );
}
