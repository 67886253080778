// import the original type declarations
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// import all namespaces (for the default language, only)
import en from "./assets/i18n/en.json";
import mn from "./assets/i18n/mn.json";

const resources = {
  mn: {
    home: mn,
  },
  en: {
    home: en,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "mn", //default language
});

export default i18next;
