import Partners from "../components/partners";
import Timeline from "../components/timeline";
import Connect from "../components/connect";
import Advantage from "../components/advantage";

import mission from "../assets/images/mission.png";
import vision from "../assets/images/vision.png";
import value from "../assets/images/value.png";

export default function Example() {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <img
          src={require("../assets/images/about-bg.png")}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
        >
          <path
            fill="url(#10724532-9d81-43d2-bb94-866e98dd6e42)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
          <defs>
            <linearGradient
              id="10724532-9d81-43d2-bb94-866e98dd6e42"
              x1="1097.04"
              x2="-141.165"
              y1=".22"
              y2="363.075"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2383c6" />
              <stop offset={1} stopColor="#3397dd" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          viewBox="0 0 1097 845"
          aria-hidden="true"
          className="absolute left-1/2 -top-52 -z-10 w-[68.5625rem] -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        >
          <path
            fill="url(#8ddc7edb-8983-4cd7-bccb-79ad21097d70)"
            fillOpacity=".2"
            d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
          />
          <defs>
            <linearGradient
              id="8ddc7edb-8983-4cd7-bccb-79ad21097d70"
              x1="1097.04"
              x2="-141.165"
              y1=".22"
              y2="363.075"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2383c6" />
              <stop offset={1} stopColor="#3397dd" />
            </linearGradient>
          </defs>
        </svg>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Бидний тухай
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Смарт Крафт ХХК нь анх 2011 онд үүсгэн байгуулагдаж Монгол улсын
              эрчим хүч, дэд бүтцийн салбарт өндөр болон нам хүчдэлийн эрчим
              хүчний тоног төхөөрөмжийн угсралт суурилуулалт, автоматик болон
              цахилгааны бүх төрлийн цогц шийдэл боловсруулах, бүх төрлийн төмөр
              хийцлэлийн бүтээгдэхүүн үйлдвэрлэх чиглэлээр тасралтгүй үйл
              ажиллагаагаа явуулж буй салбартаа танигдсан үндэсний үйлдвэрлэгч
              компани юм.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Бид гадна, дотор цахилгаан хангамж, авто зам, төмөр замын хэв
              хашмал болон бусад бүх төрлийн төмөр хийцлэлийн бүтээгдэхүүнүүдийг
              зах зээлд нийлүүлэхээс гадна инженерчлэлийн зураг төсөл
              боловсруулахаас эхлэн, тоног төхөөрөмжийн нийлүүлэлт, угсралт,
              туршилт, тохируулгын ажлыг захиалагчдад хүлээлгэн өгөх зарчмаар
              ажиллаж байна.
            </p>
          </div>
          {/* <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.name} className="flex flex-col-reverse">
                  <dt className="text-base leading-7 text-gray-300">
                    {stat.name}
                  </dt>
                  <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div> */}
        </div>
      </div>
      <div className="flex-wrap items-center justify-center gap-8 text-center sm:flex">
        <div className="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="flex items-center justify-center w-12 h-12 mx-auto text-white rounded-md">
              <img className="" src={mission} alt="" />
            </div>
          </div>
          <h3 className="py-4 text-2xl font-semibold text-gray-700 sm:text-2xl dark:text-white">
            Эрхэм зорилго
          </h3>
          <p className="py-4 text-gray-500 text-md dark:text-gray-300">
            Эрчим хүч, дэд бүтцийн чиглэлээр үйл ажиллагаа явуулдаг,
            хэрэглэгчийн сэтгэл ханамжийг дээдэлсэн, дэвшилтэт техник
            технологийг нэвтрүүлэгч, Монгол орны хөгжил дэвшилд хувь нэмэр
            оруулагч үндэсний үйлдвэрлэгч компани байх.
          </p>
        </div>
        <div className="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 sm:mt-16 md:mt-20 lg:mt-24 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="flex items-center justify-center w-12 h-12 mx-auto text-white rounded-md">
              <img className="" src={vision} alt="" />
            </div>
          </div>
          <h3 className="py-4 text-2xl font-semibold text-gray-700 sm:text-2xl dark:text-white">
            Алсын хараа
          </h3>
          <p className="py-4 text-gray-500 text-md dark:text-gray-300">
            Монгол инженерийн ур ухаан шингэсэн бүтээгдхүүнийг техник
            технологийн тусламжтайгаар олон улсын стандартад нийцүүлэн, дотоодын
            болон гадаадын зах зээлд ханган нийлүүлэгч, экспортлогч компани
            болох.
          </p>
        </div>
        <div className="w-full px-4 py-4 mt-6 bg-white rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 dark:bg-gray-800">
          <div className="flex-shrink-0">
            <div className="flex items-center justify-center w-12 h-12 mx-auto text-white rounded-md">
              <img className="" src={value} alt="" />
            </div>
          </div>
          <h3 className="py-4 text-2xl font-semibold text-gray-700 sm:text-2xl dark:text-white">
            Үнэт зүйл
          </h3>
          <p className="py-4 text-gray-500 text-md dark:text-gray-300">
            Чадварлаг бүтээлч ажилтан, Чанартай бүтээгдэхүүн, Хэрэглэгчийн
            сэтгэл ханамж.
          </p>
        </div>
      </div>

      <Timeline />
      <Advantage />
      <Connect />
      <Partners />
    </>
  );
}
