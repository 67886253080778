import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function Example() {
  return (
    <>
      <div className="mx-auto pt-10 w-full">
        <div className="w-11/12 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto sm:mb-10 mb-16">
          <h1 className=" xl:text-5xl md:text-3xl text-xl text-center text-gray-800 font-bold mb-5 pt-4">
            Бидний түүх
          </h1>
        </div>
        <div className="xl:py-0 lg:py-10 md:py-16 sm:py-10 px-2 flex flex-wrap justify-center">
            <Card
            color="blue"
            variant="gradient"
            className="w-full max-w-[15rem] p-8 m-3"
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 mb-8 rounded-none border-b border-white/10 text-center"
            >
              <Typography
                variant="h5"
                color="white"
                className="font-normal uppercase"
              >
                2011 он
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    “Смарт Крафт” ХХК үүсэн байгуулагдаж үүд хаалгаа нээв.
                  </Typography>
                </li>
              </ul>
            </CardBody>
          </Card>
          <Card
            color="blue"
            variant="gradient"
            className="w-full max-w-[15rem] p-8 m-3"
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 mb-8 rounded-none border-b border-white/10 text-center"
            >
              <Typography
                variant="h5"
                color="white"
                className="font-normal uppercase"
              >
                2014 он
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    Төмөр хийцлэлийн үйлдвэр бүтээн байгуулан, жижиг дунд
                    үйлдвэрлэгч болов.
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    0.4-110 кВ-ын Цахилгаан дамжуулах агаарын шугам, дэд станцын
                    ажил үйлчилгээ хийх тусгай зөвшөөрөл авав.
                  </Typography>
                </li>
              </ul>
            </CardBody>
          </Card>
          <Card
            color="blue"
            variant="gradient"
            className="w-full max-w-[15rem] p-8 m-3"
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 mb-8 rounded-none border-b border-white/10 text-center"
            >
              <Typography
                variant="h5"
                color="white"
                className="font-normal uppercase"
              >
                2017 он
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    Үйлдвэрт бүтээгдэхүүн үйлдвэрлэх албан ёсны тохирлын
                    гэрчилгээ авав.
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    АВВ группын албан ёсны дистрибьютер болов.
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    110 кВ-ын салхин цахилгаан станцыг барьж байгуулав.
                  </Typography>
                </li>
              </ul>
            </CardBody>
          </Card>
          <Card
            color="blue"
            variant="gradient"
            className="w-full max-w-[15rem] p-8 m-3"
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 mb-8 rounded-none border-b border-white/10 text-center"
            >
              <Typography
                variant="h5"
                color="white"
                className="font-normal uppercase"
              >
                2018 он
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    Замын-Үүд дэхь Монголын хамгийн том 15мВт ийн нарны
                    цахилгаан станцыг барив.
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    ISO 9001:2015 чанарын менежментийн тогтолцоог нэвтрүүлэв.
                  </Typography>
                </li>
              </ul>
            </CardBody>
          </Card>
          <Card
            color="blue"
            variant="gradient"
            className="w-full max-w-[15rem] p-8 m-3"
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 mb-8 rounded-none border-b border-white/10 text-center"
            >
              <Typography
                variant="h5"
                color="white"
                className="font-normal uppercase"
              >
                2019 он
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    Тайгам-Алтай групп компаний “Шинэ-Алтай” хотхоны орон сууцны
                    цахилгаан хангамжийг бүрэн хийж гүйцэтгэв.
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                    Авто болон төмөр замын хэв хашмалыг гүйцэтгэж, үйл
                    ажиллагаагаа өргөжүүлэв.
                  </Typography>
                </li>
              </ul>
            </CardBody>
          </Card>
          <Card
            color="blue"
            variant="gradient"
            className="w-full max-w-[15rem] p-8 m-3"
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="m-0 mb-8 rounded-none border-b border-white/10 text-center"
            >
              <Typography
                variant="h5"
                color="white"
                className="font-normal uppercase"
              >
                2022 он
              </Typography>
            </CardHeader>
            <CardBody className="p-0">
              <ul className="flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                  Үйлдвэрийн барилгын өргөтгөл хийв.
                  </Typography>
                </li>
                <li className="flex items-center gap-4">
                  <span className="rounded-full border border-white/20 bg-white/20 p-1">
                    <CheckIcon strokeWidth={2} className="h-3 w-3" />
                  </span>
                  <Typography className="font-normal">
                  Булган аймаг, Тэшиг сум 35кВ-ын 71.2км цахилгаан дамжуулах агаарын шугам барьж байгуулав.
                  </Typography>
                </li>
              </ul>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
